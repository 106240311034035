import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { login_error, login_loading, login_sucess, logout } from "./AuthTypes";
import AuthReducer from "./AuthReducer";
import { useNavigate } from "react-router-dom";
import { ToastContainer as Toaster, toast } from "react-toastify";


export const AuthContext = createContext();
const token = localStorage.getItem("token") || null;
export const AUTH_BASE_URL = "https://auth.brainbucks.in";
export const QUIZ_BASE_URL = "https://quiz.brainbucks.in";
export const NOTIFY_BASE_URL = "https://notify.brainbucks.in";
export const SALES_BASE_URL = "https://sales.brainbucks.in";
export const PREREC_BASE_URL = "https://prerec.brainbucks.in";
export const BASE_URL = "https://notify.brainbucks.in";
export const IMAGE_URL =
  "https://auth.brainbucks.in/stream/get/public?blobname=";
export const SOCKET_URL  = "https://socket.brainbucks.in";

export const BB_URl = "https://brainbucks.in";

const initAuth = {
  isLoading: false,
  isError: false,
  isAuth: false,
  token: token,
};

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(AuthReducer, initAuth);
  const [queRefresh, setQueRefresh] = useState(true);
  const [quizzRefresh, setQuizzRefresh] = useState(true);
  const [triviaRefresh, settriviaRefresh] = useState(true);
  const [salesSearch, setSalesSearch] = useState("");
  const [currentPageHistory, setCurrentPageHistory] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [getDeptRefresh, setGetDeptRefresh] = useState(false);
  const [idNotify, setIdNotify] = useState("");

  const refreshQue = () => {
    setQueRefresh(!queRefresh);
  };
  const [taskCount, setTaskCount] = useState({
    myTask: 0,
    notStarted: 0,
    inProgress: 0,
    complete: 0,
    missed: 0,
  });

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [toDate, setToDate] = useState(getCurrentDate());
  const [fromDate, setFromDate] = useState(getCurrentDate());

  // states for change password
  const [showChangePass, setShowChangePass] = useState(false);

  const handleShowChangePass = () => {
    setShowChangePass(true);
    console.log("count");
  }
  const handleCloseChangePass = () => setShowChangePass(false);

  //function for login
  const [access, setaccess] = useState([]);
  const loginFun = async (user) => {
    const { email, password } = user;
    dispatch({ type: login_loading });
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
      fcm_key: localStorage.getItem("firebase_token"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${AUTH_BASE_URL}/auth/admin/login`,
        requestOptions
      );

      const result = await response.json();
      if (result.status == 1) {
        dispatch({
          type: login_sucess,
          payload: result.token,
          get_access: result.access,
          get_admin: result.admin,
        });
        // navigate("/Home");
        localStorage.setItem("user_id", result.user_id);
        setaccess(result.access);
        navigate(`/${result.default_panel}`);
      } else if (result.Backend_Error) {
        toast(result.Backend_Error);
      }
      
      else {
        toast(result.message);
        dispatch({ type: login_error });
      }
    } catch (error) {
      toast(error);
      dispatch({ type: login_error });
    }
  };

  //function for logout
  const logoutFun = async () => {
    
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")} `
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${AUTH_BASE_URL}/auth/admin/logout`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        dispatch({ type: logout });
        navigate("/");
        toast(result.message);
      } else {
        toast(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const originalFetch = fetch
    global.fetch = async (...args) => {
     const response = await originalFetch(...args);
     
     const clone = response.clone();
     
     clone.json().then(data => {
       if (data.status === "TOKEN_ERR") {
         navigate("/");
         toast("Logged in another device, please login again");
       }
     }).catch(error => {
       console.error("Error parsing JSON:", error);
     });
   
     return response;
   };
  }, [])
 

  // function got change password


  return (
    <>
      <AuthContext.Provider
        value={{
          state,
          loginFun,
          logoutFun,
          fromDate,
          setFromDate,
          toDate,
          queRefresh,
          setQueRefresh,
          setToDate,
          refreshQue,
          taskCount,
          setTaskCount,
          quizzRefresh,
          setQuizzRefresh,
          triviaRefresh,
          settriviaRefresh,
          salesSearch,
          setSalesSearch,
          handleShowChangePass,
          handleCloseChangePass,
          showChangePass,
          currentPageHistory,
          setCurrentPageHistory,
          hasMore,
          setHasMore,
          isLoading,
          setIsLoading,
          getDeptRefresh,
          setGetDeptRefresh,
          idNotify,
          setIdNotify
        }}
      >
        {children}
      </AuthContext.Provider>
      <Toaster />
    </>
  );
};
